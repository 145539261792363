import { couch_uuid, id } from './fields'

export const schema = {
	title: 'service panel item schema',
	description: 'describes a single service panel item',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		
		id: id,

		service_uuid: couch_uuid,
		panel_uuid: couch_uuid,
	},
}

export default schema
