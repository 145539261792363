import { couch_uuid, id } from './fields'

export const schema = {
	title: 'service panel schema',
	description: 'describes a single service panel',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		
		id: id,

		name: {
			type: 'string',
		},
		tab_name: {
			type: 'string',
		},
		// org_unit_id_ref: {
		// 	...id,
		// },
		org_unit_uuid: couch_uuid,
	},
}

export default schema
