const devStatusesMissingStyles = []

const config = [
	{
		statuses: [
			'Podano',
			'Zmienione wyniki',
			'Wykonano',
			'Częściowe wyniki',
			'Wyniki do weryf.',
			'ZmWynik',
			
			//zg
			'WYKO',
		],
		norm: [],
		data: {
			'bg-class': 'bg-green',
			'icon-class': 'fa-solid fa-square-check',
		},
	},
	{
		statuses: [
			'Anulowano zlecenie',
			'Odstawiono',
			'Anulowane zlecenie',
			'Anulowane po rejestr.',
			
			// zg
			'ANUL',
			'NWYK',
			'ODRZ',
		],
		norm: ['upper'],
		data: {
			'bg-class': 'bg-red',
			'icon-class': 'fa-solid fa-square-xmark',
		},
	},
	{
		statuses: [
			'Zarejestrowane',
			'Nowe zlecenie',
			'Zlecono',
			
			// zg
			'GTRE',
			'WYSL',
			
			// wr
			'NAUT', // Nieautoryzowany / Wynik nieopisany - “w toku” (niebieski)
			'OPRA', // Opracowywany - “w toku” (niebieski)
			'REAL', // Realizowane - “w toku” (niebieski)
			'NADA', // Nadane - “w toku” (niebieski)
			'PRZY' // “w toku” (niebieski)
		],
		norm: ['lower'],
		data: {
			'bg-class': 'bg-blue',
			'icon-class': 'fa-solid fa-square-arrow-right',
		},
	},
	{
		statuses: [
			'Adnotacja',
		],
		norm: ['lower'],
		data: {
			'bg-class': 'bg-blue',
			'icon-class': 'fa-solid fa-notes-medical',
		},
	},
	{
		statuses: [
			'Wyniki',
			'Wynik',
			
			//gl
			'WYN',
		],
		norm: [],
		data: {
			'bg-class': 'bg-green',
			'icon-class': 'fa-solid fa-square-check',
		},
	},
	{
		statuses: [null, undefined, 
			'Oczekująca wysyłka do CN', 
			'Wysyłanie do CN', 
			'Wysłane do CN'
		],
		norm: [],
		data: {
			'bg-class': 'bg-gray',
			'icon-class': null,
		},
	},
	{
		statuses: [],
		norm: [],
		data: {
			'bg-class': null,
			'icon-class': null,
		},
	},
]

export const getStyleByStatus = function (status) {
		let i = config.findIndex((e) =>
			e['statuses']?.includes(status) ? true : false
		)

		if (i >= 0) return config[i].data
		else if (process.env.NODE_ENV === 'development')
			if (!devStatusesMissingStyles.includes(status)) {
				devStatusesMissingStyles.push(status)
				console.debug(
					'Brak zdefiniowanego stylu dla statusu: ' +
						JSON.stringify(devStatusesMissingStyles)
				)
			}

		return config[config.length - 1].data
	},
	getStyleByNorm = function (norm) {
		let i = config.findIndex((e) =>
			e['norm']?.includes(norm) ? true : false
		)

		if (i >= 0) return config[i].data

		return config[config.length - 1].data
	},
	statusesResults = config[0].statuses,
	statusesResults2 = config[4].statuses,
	statusesPending = config[2].statuses,
	statusesCancelled = config[1].statuses,
	statusIsResults = (s) => statusesResults.includes(s),
	statusIsResults2 = (s) => statusesResults2.includes(s),
	statusIsPending = (s) => statusesPending.includes(s)

export default getStyleByStatus
